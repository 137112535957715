export const CHANNEL_OPTIONS = {
  1: {
    key: 1,
    text: 'OpenAI',
    value: 1,
    color: 'success'
  },
  14: {
    key: 14,
    text: 'Anthropic Claude',
    value: 14,
    color: 'primary'
  },
  33: {
    key: 33,
    text: 'AWS',
    value: 33,
    color: 'primary'
  },
  37: {
    key: 37,
    text: 'Cloudflare',
    value: 37,
    color: 'success'
  },
  3: {
    key: 3,
    text: 'Azure OpenAI',
    value: 3,
    color: 'success'
  },
  11: {
    key: 11,
    text: 'Google PaLM2',
    value: 11,
    color: 'warning'
  },
  24: {
    key: 24,
    text: 'Google Gemini',
    value: 24,
    color: 'warning'
  },
  28: {
    key: 28,
    text: 'Mistral AI',
    value: 28,
    color: 'warning'
  },
  40: {
    key: 40,
    text: '字节跳动豆包',
    value: 40,
    color: 'primary'
  },
  15: {
    key: 15,
    text: '百度文心千帆',
    value: 15,
    color: 'primary'
  },
  17: {
    key: 17,
    text: '阿里通义千问',
    value: 17,
    color: 'primary'
  },
  18: {
    key: 18,
    text: '讯飞星火认知',
    value: 18,
    color: 'primary'
  },
  16: {
    key: 16,
    text: '智谱 ChatGLM',
    value: 16,
    color: 'primary'
  },
  19: {
    key: 19,
    text: '360 智脑',
    value: 19,
    color: 'primary'
  },
  25: {
    key: 25,
    text: 'Moonshot AI',
    value: 25,
    color: 'primary'
  },
  23: {
    key: 23,
    text: '腾讯混元',
    value: 23,
    color: 'primary'
  },
  26: {
    key: 26,
    text: '百川大Model',
    value: 26,
    color: 'primary'
  },
  27: {
    key: 27,
    text: 'MiniMax',
    value: 27,
    color: 'primary'
  },
  29: {
    key: 29,
    text: 'Groq',
    value: 29,
    color: 'primary'
  },
  30: {
    key: 30,
    text: 'Ollama',
    value: 30,
    color: 'primary'
  },
  31: {
    key: 31,
    text: '零一万物',
    value: 31,
    color: 'primary'
  },
  32: {
    key: 32,
    text: '阶跃星辰',
    value: 32,
    color: 'primary'
  },
  34: {
    key: 34,
    text: 'Coze',
    value: 34,
    color: 'primary'
  },
  35: {
    key: 35,
    text: 'Cohere',
    value: 35,
    color: 'primary'
  },
  36: {
    key: 36,
    text: 'DeepSeek',
    value: 36,
    color: 'primary'
  },
  38: {
    key: 38,
    text: 'DeepL',
    value: 38,
    color: 'primary'
  },
  39: {
    key: 39,
    text: 'together.ai',
    value: 39,
    color: 'primary'
  },
  42: {
    key: 42,
    text: 'VertexAI',
    value: 42,
    color: 'primary'
  },
  43: {
    key: 43,
    text: 'Proxy',
    value: 43,
    color: 'primary'
  },
  44: {
    key: 44,
    text: 'SiliconFlow',
    value: 44,
    color: 'primary'
  },
  45: {
    key: 45,
    text: 'xAI',
    value: 45,
    color: 'primary'
  },
  45: {
    key: 46,
    text: 'Replicate',
    value: 46,
    color: 'primary'
  },
  41: {
    key: 41,
    text: 'Novita',
    value: 41,
    color: 'purple'
  },
  8: {
    key: 8,
    text: 'CustomChannel',
    value: 8,
    color: 'error'
  },
  22: {
    key: 22,
    text: '知识库：FastGPT',
    value: 22,
    color: 'success'
  },
  21: {
    key: 21,
    text: '知识库：AI Proxy',
    value: 21,
    color: 'success'
  },
  20: {
    key: 20,
    text: 'Proxy：OpenRouter',
    value: 20,
    color: 'success'
  },
  2: {
    key: 2,
    text: 'Proxy：API2D',
    value: 2,
    color: 'success'
  },
  5: {
    key: 5,
    text: 'Proxy：OpenAI-SB',
    value: 5,
    color: 'success'
  },
  7: {
    key: 7,
    text: 'Proxy：OhMyGPT',
    value: 7,
    color: 'success'
  },
  10: {
    key: 10,
    text: 'Proxy：AI Proxy',
    value: 10,
    color: 'success'
  },
  4: {
    key: 4,
    text: 'Proxy：CloseAI',
    value: 4,
    color: 'success'
  },
  6: {
    key: 6,
    text: 'Proxy：OpenAI Max',
    value: 6,
    color: 'success'
  },
  9: {
    key: 9,
    text: 'Proxy：AI.LS',
    value: 9,
    color: 'success'
  },
  12: {
    key: 12,
    text: 'Proxy：API2GPT',
    value: 12,
    color: 'success'
  },
  13: {
    key: 13,
    text: 'Proxy：AIGC2D',
    value: 13,
    color: 'success'
  }
};
